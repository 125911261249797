// multiselect
.p-multiselect{
    width: 100%;
}

// progressbar
.p-progressbar .p-progressbar-value {
    background: var(--color-accent);
}

// button
.p-button {
    background: var(--color-accent);
    border-color: var(--color-accent);
    &:enabled:hover {
        background:  var(--color-accent-hover);
        border-color:  var(--color-accent-hover);
    }
}

// datatable
.p-datatable{
    tr.p-element.inactive {
        opacity: 0.65;
    }
}

// selectbutton
.p-selectbutton {
    .p-button-label {
        transition: none;
    }
}
.p-selectbutton .p-button.p-highlight {
    background: var(--color-accent);
    border-color: var(--color-accent-hover);
}

.p-selectbutton .p-button.p-highlight:hover{
    background: var(--color-accent);
    border-color: var(--color-accent-hover);
}

// inputtext
.p-inputtext {
    color: #000000;
}

// inputswitch
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--color-accent);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: var(--color-accent-hover);
}

// dropdown
p-dropdown.w-100 {
    .p-dropdown {
        width: 100%;
    }
}

// p-tabmenu
.p-tabmenu{
    .p-tabmenu-nav{
      .p-tabmenuitem{
        .p-menuitem-link:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
      }
    }
  }