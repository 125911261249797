
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$testo: (
 50: #e9f1f7,
 100: #cddce3,
 200: #b1c5cf,
 300: #93acba,
 400: #7b99a9,
 500: #648799,
 600: #577887,
 700: #486371,
 800: #3a505b,
 900: #293b43,
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: white,
   400: white,
   500: white,
   600: white,
   700: white,
   800: white,
   900: white,
 )
);

$testo-secondary: (
    50: #fff3e0,
    100: #ffe0b2,
    200: #ffcd80,
    300: #ffb84d,
    400: #ffa826,
    500: #ff9900,
    600: #fb8d00,
    700: #f57d00,
    800: #ef6d00,
    900: #e65200,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    )
);

$Testo-Admin-primary: mat.define-palette($testo, 500);
$Testo-Admin-accent: mat.define-palette($testo-secondary, 500);

// The warn palette is optional (defaults to red).
$Testo-Admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Testo-Admin-theme: mat.define-light-theme((
  color: (
    primary: $Testo-Admin-primary,
    accent: $Testo-Admin-accent,
    warn: $Testo-Admin-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Testo-Admin-theme);

// Plus imports for other components in your app.

@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
//@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "assets/css/primeng-custom.scss";
@import "assets/css/spinner.scss";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Quill */

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; font-size: 14px; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
:root {
  --color-accent: #ff9900;
  --color-accent-hover: #e68a00;
  --color-secondary: #648799;
  --mdc-list-list-item-label-text-size: 16px;
 }

.fancybox__container{
  z-index: 1056 !important;
  --fancybox-bg: rgba(24, 24, 27, 0.9);
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1,
.mat-typography .h1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar .h1, .mat-toolbar h2, .mat-toolbar .h2, .mat-toolbar h3, .mat-toolbar .h3, .mat-toolbar h4, .mat-toolbar .h4, .mat-toolbar h5, .mat-toolbar .h5, .mat-toolbar h6, .mat-toolbar .h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-form-field-subscript-wrapper{
  display: none;
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: #fff;
}

.cdk-overlay-container {
  z-index: 1100;
}

h1{
  .mat-icon {
    vertical-align: sub;
    margin-right: 16px;
    font-size: 30px;
  }
}

.mat-icon {
  vertical-align: middle;
}

.modal-dialog{
  max-width: 900px;
}
.modal-xl {
  max-width: 1140px;
}

.required_icon{
  color: red;
  margin-left: 2px;
}

.chars-counter{
  float: right;
}

.empty-text{
  text-align: center;
  margin-top: 20px;
  font-size: 1.3em;
  color: gray;
}

.active-label {
  font-weight: 500;
}

.top-buttons{
  button{
      &:not(:last-child){
          margin-right: 8px;
      }

      mat-spinner{
          display: inline-block;
          margin-right: 6px;
          vertical-align: middle;
          circle {
              stroke: #ffffff;
          }
      }
  }
}

p-table tr {
  .round-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 16px;
  }
}

.action-REALIZADA {
  background-color: rgb(109 255 115 / 21%) !important;
}
.action-ATRASADA{
  background-color: rgba(255, 109, 109, 0.21) !important;
}
.cursor-pointer{
  cursor: pointer;
}
.highlightedDay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color-accent);
  color: #fff;
  font-weight: bold;

  &:hover {
    background-color: var(--color-accent-hover) !important;
  }
}
.opa-load{
  opacity: 0.4;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-section-h {
  display: flex;
  gap: 10px;
  align-items: center;
}

// WYSING ql-editor
.ql-editor strong {
  font-weight: 800;
}